import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/anchor",
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "home" */ "../components/Home.vue"),
    meta: { title: "自述文件" },
    children: [
      {
        path: "/anchor",
        name: "anchor",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Anchor.vue"),
        meta: { title: "用户" },
      },
      {
        path: "/data",
        name: "data",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Data.vue"),
        meta: { title: "数据" },
      },
      {
        path: "/mine",
        name: "mine",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/Mine.vue"),
        meta: { title: "我的" },
      },
    ],
  },
  {
    path: "/login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: { title: "登录" },
  },
  {
    path: "/invite",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Invite.vue"),
    meta: { title: "注册" },
  },
  {
    path: "/regSuccess",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/RegSuccess.vue"),
    meta: { title: "注册成功" },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
