import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vant, { Toast } from "vant";
import "vant/lib/index.css";

Vue.config.productionTip = false;

Vue.use(Vant);

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 嗨皮合作方`;
  const role = localStorage.getItem("xd_partner_user_token");
  if (
    !role &&
    to.path !== "/login" &&
    to.path !== "/invite" &&
    to.path !== "/regSuccess"
  ) {
    Toast.fail("请先登录");
    next("/login");
  } else {
    next();
  }
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
